import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from '../../components/Grid';
import DeleteBtn from '../../components/DeleteBtn';
import API from '../../utils/API';
import { List, ListItem } from '../../components/List';
import { Input, FormBtn } from '../../components/Form';

let selectPlayers = [];

class Players extends Component {
	state = {
		book: {},
		name: "",
		players: []
	};

	componentDidMount() {
		this.loadPlayers();
	}

	loadPlayers = () => {
		API.getPlayers()
			.then(res => {
				var playersSelect = res.data.map(player => ({value: player.name, label:player.name}));
				selectPlayers = playersSelect;
				this.setState({ players: res.data, name: "" });
			})
			.catch( (err) => {
			});
	};

	deletePlayer = id => {
		if (window.confirm("Spieler wirklich entfernen?")) {	
			API.deletePlayer(id)
				.then(res => this.loadPlayers())
				.catch(err => console.log(err));
		}
	};

	handleInputChange = event => {
		const { name, value } = event.target;
		this.setState({
			[name]: value
		});
	};

	handleFormSubmit = event => {
		event.preventDefault();
		if (this.state.name) {
			API.savePlayer({
				name: this.state.name
			})
				.then(res => this.loadPlayers())
				.catch(err => console.log(err));
		}
	};

	render() {
		return (
			<Container fluid>
				<Row>
					<Col size="md-6">
						<h5> Neuer Spieler </h5>
						<form> 
							<Input
								value={this.state.name}
								onChange={this.handleInputChange}
								name="name"
								placeholder="Name"
							/>

							<FormBtn
								disabled={!(this.state.name)}
								onClick={this.handleFormSubmit}
							>
								Spieler hinzufügen
							</FormBtn>
						</form>
					</Col>
					<Col size="md-6 sm-12">
						<h5> Spieler</h5>
						{this.state.players.length ? (
							<List>
								{this.state.players.map(player => (
									<ListItem key={player._id}>
										<Link to={'/players/' + player._id}>
											<strong>
												{player.name}
											</strong>
										</Link>
										<DeleteBtn onClick={() => this.deletePlayer(player._id)} />
									</ListItem>
								))}
							</List>
						) : (
							<p>Keine Spieler</p>
						)}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Players;
