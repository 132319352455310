import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navigation = (props) => (

<Navbar variant="dark" bg="dark" expand="lg"  sticky="top">
<Container fluid>
  <Navbar.Brand href="#">🏓 Ping Pong Liga</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />

  <Navbar.Collapse id="basic-navbar-nav">

  	<Nav className="me-auto">
	  <Nav.Link href="#/">Home</Nav.Link>

	  <NavDropdown
		id="nav-dropdown-dark-example"
		title="Historie"
		>
		<NavDropdown.Item href="#/">2023</NavDropdown.Item>
		<NavDropdown.Divider />
		<NavDropdown.Item href="#history/2022">2022</NavDropdown.Item>
		<NavDropdown.Item href="#history/2021">2021</NavDropdown.Item>
	  </NavDropdown>
	</Nav>
	</Navbar.Collapse>

</Container>
</Navbar>
  
);

export default Navigation;
