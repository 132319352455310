import React, { Component } from 'react';
import DeleteBtn from '../../components/DeleteBtn';
import API from '../../utils/API';
import { Col, Row, Container } from '../../components/Grid';
import { List, ListItem } from '../../components/List';
import {FormBtn } from '../../components/Form';
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';


let selectPlayers = [];

const columns = [{
	dataField: 'rank',
	text: '#'
  }, {
	dataField: 'name',
	text: 'Name'
  }, {
	dataField: 'games',
	text: 'Spiele'
  }, {	
	dataField: 'wins',
  	text: 'Siege'
	}, {
	dataField: 'losses',
	text: 'Niedrl'
	},{
	dataField: 'points',
	text: 'Pkte'
	}];

class Books extends Component {

	state = {
		players: [],
		name: '',
		games: [],
		winner: '',
		looser: '',
		tableData: [],
		isOpen: false
	};

	openModal = () => this.setState({isOpen: true});
	closeModal = () => this.setState({isOpen: false});

	componentDidMount() {
		this.loadPlayers();
		this.loadGames()
	}

	loadPlayers = () => {
		API.getPlayers()
			.then(res => {
				var playersSelect = res.data.map(player => ({value: player.name, label:player.name}));
				selectPlayers = playersSelect;
				this.setState({ players: res.data, name: "" });
				this.updateTable(res.data, this.state.games);
			})
			.catch( (err) => {
			});
	};

	loadGames = () => {
		API.getGamesByDates("2023-01-01", "2023-12-31")
			.then(res => {
				this.setState({ games: res.data, winner: "", looser:"" })
				this.updateTable(this.state.players, res.data);
			})
			.catch( (err) => {
				console.log(err);
			});
	};

	updateTable = (players, games) => {
		var winLoss = games.reduce(function (acc, curr) {
			acc.wins[curr.winner] = acc.wins[curr.winner] ? acc.wins[curr.winner] + 1 : 1;
			acc.losses[curr.looser] = acc.losses[curr.looser] ? acc.losses[curr.looser] + 1 : 1;
			return acc;
		}, {wins:{}, losses: {}});
		
		var reducedPlayers = players.reduce(function (acc, player) {
			let wins = winLoss.wins[player.name] || 0;
			let losses = winLoss.losses[player.name] || 0;
			let games = wins + losses;
			let points = 2*wins + losses; // Punkte: Sieg gibt 2, Niederlage 1 Punkt
			acc.push({name: player.name, games: games, wins: wins, losses: losses, points: points })
			return acc;
		},[]);
		
		var sortedPlayers = reducedPlayers.sort((p1, p2) => p2.points - p1.points || p2.wins - p1.wins);
		sortedPlayers.forEach( (player, index) => player.rank = ++index );

		this.setState({tableData: sortedPlayers});
	};

	deleteGame = id => {
		if (window.confirm("Spiel wirklich entfernen?")) {	
			API.deleteGame(id)
				.then(res => this.loadGames())
				.catch(err => console.log(err));
		}
	};

	handleNewGameSubmit = event => {
		event.preventDefault();
		if (this.state.winner && this.state.looser) {
			API.saveGame({
				winner: this.state.winner,
				looser: this.state.looser
			})
				.then(res => this.loadGames())
				.catch(err => console.log(err));
		}
	};

	render() {
		return (
			<Container fluid>
				<Row >
					<Col size="md-6">
						<h5> Neues Spiel </h5>
						<form>							
							<Select options={selectPlayers} 
								placeholder="Gewinner"
								onChange={(event) => this.setState({winner: event.value})}
							/>

							<Select options={selectPlayers} 
								placeholder="Verlierer"
								onChange={(event) => this.setState({looser: event.value})}							/>
							<FormBtn
								disabled={!(this.state.winner && this.state.looser && this.state.looser !== this.state.winner)}
								onClick={this.handleNewGameSubmit}
							>
								Spiel hinzufügen
							</FormBtn>
						</form>
					</Col>
				</Row>
				<Row>
					<Col size="md-6">
						<h5> Rangliste </h5>
						<BootstrapTable class=".w-auto .th-sm" defaultSorted={[{ dataField: 'average', order: 'desc'}]}
										keyField='name' 
										data={ this.state.tableData } 
										columns={ columns } 
										/>

					</Col>
					<Col size="md-6">
						<h5> Spiele </h5>
						{this.state.games.length ? (
							<List>
								{this.state.games.map(game => (
									<ListItem key={game._id}>
										<div style={{width:"100%"}}> 
											<div style={{ float:"left"}}>
												<span>Sieger: </span> <h5 style={{ display:'inline' }}>{game.winner}</h5> <span> - Verlierer: </span> <h5 style={{ display:'inline' }}>{game.looser}</h5>
											</div>
											<div style={{width: "10%", float: "right"}}>
												<DeleteBtn onClick={() => this.deleteGame(game._id)} />
											</div>
										</div>
									</ListItem>
								))}
							</List>
						) : (
							<p>Keine Spiele</p>
						)}
					</Col>
				</Row>

			</Container>
		);
	}
}

export default Books;
