import axios from 'axios';

export default {
	// Gets all games
	getAllGames: function() {
		return axios.get('/api/games');
	},
	// Get all games by year
	getGamesByDates: function(startDate, endDate) {
		return axios.get('/api/games', {params : {
			start: startDate,
			end: endDate
		}});
	},
	// Gets the game with the given id
	getGame: function(id) {
		return axios.get('/api/games/' + id);
	},
	// Deletes the game with the given id
	deleteGame: function(id) {
		return axios.delete('/api/games/' + id);
	},
	// Saves a game to the database
	saveGame: function(game) {
		return axios.post('/api/games', game);
	},
	// Gets all players
	getPlayers: function() {
		return axios.get('/api/players');
	},
	// Gets the book with the given id
	getPlayer: function(id) {
		return axios.get('/api/players/' + id);
	},
	// Deletes the book with the given id
	deletePlayer: function(id) {
		return axios.delete('/api/players/' + id);
	},
	// Saves a book to the database
	savePlayer: function(player) {
		return axios.post('/api/players', player);
	}
};
