import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Books from './pages/Books';
import Players from './pages/Players';
import History from './pages/History';
import NoMatch from './pages/NoMatch';
import Navigation from './components/Navigation';

const App = () => (
	<Router>
		<div>
			<Navigation actionName={Books.showDialog}/>
			<Switch>
				<Route exact path="/" component={Books} />
				<Route exact path="/books" component={Books} />
				<Route exact path="/players" component={Players} />
				<Route exact path="/history/:year" component={History} />
				<Route component={NoMatch} />
			</Switch>
		</div>
	</Router>
);

export default App;
