import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from '../../components/Grid';
import API from '../../utils/API';
import { List, ListItem } from '../../components/List';
import BootstrapTable from 'react-bootstrap-table-next';


const columns = [{
	dataField: 'rank',
	text: '#'
  }, {
	dataField: 'name',
	text: 'Name'
  }, {
	dataField: 'games',
	text: 'Spiele'
  }, {	
	dataField: 'wins',
  	text: 'Siege'
	}, {
	dataField: 'losses',
	text: 'Niedrl'
	},{
	dataField: 'points',
	text: 'Pkte'
	}];

let selectPlayers = [];


class History extends Component {
	state = {
		players: [],
		name: '',
		games: [],
		winner: '',
		looser: '',
		tableData: []
	};

	componentDidMount() {
		this.loadPlayers();
		this.loadGames()
	}

  componentDidUpdate(prevProps) {
		if (prevProps.match.params.year !== this.props.match.params.year) {
      this.loadGames()
		}
	}

	loadPlayers = () => {
		API.getPlayers()
			.then(res => {
				var playersSelect = res.data.map(player => ({value: player.name, label:player.name}));
				selectPlayers = playersSelect;
				this.setState({ players: res.data, name: "" });
				this.updateTable(res.data, this.state.games);
			})
			.catch( (err) => {
			});
	};

	loadGames = () => {
    let year = this.props.match.params.year
		API.getGamesByDates(year+"-01-01", year+"-12-31")
			.then(res => {
				this.setState({ games: res.data, winner: "", looser:"" })
				this.updateTable(this.state.players, res.data);
			})
			.catch( (err) => {
				console.log(err);
			});
	};

  updateTable = (players, games) => {
		var winLoss = games.reduce(function (acc, curr) {
			acc.wins[curr.winner] = acc.wins[curr.winner] ? acc.wins[curr.winner] + 1 : 1;
			acc.losses[curr.looser] = acc.losses[curr.looser] ? acc.losses[curr.looser] + 1 : 1;
			return acc;
		}, {wins:{}, losses: {}});
		
		var reducedPlayers = players.reduce(function (acc, player) {
			let wins = winLoss.wins[player.name] || 0;
			let losses = winLoss.losses[player.name] || 0;
			let games = wins + losses;
			let points = 2*wins + losses; // Punkte: Sieg gibt 2, Niederlage 1 Punkt
			acc.push({name: player.name, games: games, wins: wins, losses: losses, points: points })
			return acc;
		},[]);
		
		var sortedPlayers = reducedPlayers.sort((p1, p2) => p2.points - p1.points || p2.wins - p1.wins);
		sortedPlayers.forEach( (player, index) => player.rank = ++index );

		this.setState({tableData: sortedPlayers});
	};

	render() {
		return (
			<Container fluid>
				<Row>
					<Col size="md-6">
						<h5> Jahr {this.props.match.params.year} </h5>
					</Col>
        </Row>
        <Row>
					<Col size="md-6">
						<h5> Rangliste </h5>
						<BootstrapTable class=".w-auto .th-sm" defaultSorted={[{ dataField: 'average', order: 'desc'}]}
										keyField='name' 
										data={ this.state.tableData } 
										columns={ columns } 
										/>

					</Col>
					<Col size="md-6">
						<h5> Spiele </h5>
						{this.state.games.length ? (
							<List>
								{this.state.games.map(game => (
									<ListItem key={game._id}>
										<div style={{width:"100%"}}> 
											<div style={{ float:"left"}}>
												<span>Sieger: </span> <h5 style={{ display:'inline' }}>{game.winner}</h5> <span> - Verlierer: </span> <h5 style={{ display:'inline' }}>{game.looser}</h5>
											</div>
										</div>
									</ListItem>
								))}
							</List>
						) : (
							<p>Keine Spiele</p>
						)}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default History;

